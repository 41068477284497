<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-dark.png" alt height="70" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" height="70" style="padding: 0px 0px 2px 22px;"  />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-dark.png" alt height="70" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="70" style="padding: 0px 0px 2px 22px;" />
            </span>
          </a>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

      </div>

      <div class="d-flex">

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

         <!-- <b-dropdown right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="ri-message-line"></i>
            <span class="noti-dot"></span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Messages</h6>
              </div>
              <div class="col-auto">
                <router-link class="small" :to="{ path: '/chat/'}"> View All</router-link>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;">
            <a href class="text-reset notification-item" v-for="(item, index) in chatData" :key="index" >
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{item.user.fullName}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{item.message}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ item.messageDate}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
        </b-dropdown> -->

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="fileUrl+((Cuser.image!=null && Cuser.image!=' ')? ('/files/images/users/'+Cuser.image):'/files/images/logo-sm-light.png')"
              :alt="Cuser.fullname"
            />
            <span class="d-xl-inline-block ml-1">{{Cuser.fullname+" ("+Cuser.rol+")"}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a class="dropdown-item" :href="'/userprofile/'+Cuser.id">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.kevin.list.logout') }}
          </a>
        </b-dropdown>

    
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios" 
//import simplebar from "simplebar-vue";
import i18n from "../i18n";
import test from "@/app.config.json";
let user = JSON.parse(localStorage.getItem('user'));
export default 
{
  data() 
  {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French"
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish"
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese"
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic"
        }
      ],
      current_language: "en",
      Cuser: user,
      chatData: null, 
      baseUrl:test.baseUrl,
      fileUrl:test.fileUrl
    };
  },
  components: { 
   // simplebar 
  },
  methods: 
  {
    toggleMenu() 
    {
      this.$parent.toggleMenu();
    },
    initFullScreen() 
    {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
    fetchData() { 
      axios.get(this.baseUrl + "/General/Chat/Conversations/GetByUser/"+this.Cuser.id,{
      headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`
          }
        }) 
        .then(response => 
        { 
           this.chatData=response.data.chats;
        }).catch((error) =>{
          const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
        }) 
    } 
  },
  created:function() {
    //this.fetchData()
  },
};

</script>